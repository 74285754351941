import '/@front:css/style.css'
import 'floating-vue/dist/style.css'

import FloatingVue from 'floating-vue'
import { createApp } from 'vue'
import VueClickAway from 'vue3-click-away'

import App from './App.vue'
import { registerPlugins } from './plugins/register'
import logger from './utils/logger'
import { router } from './utils/routing/router'

const app = createApp(App)

app.use(router)
app.use(VueClickAway)
app.use(FloatingVue, {
  themes: {
    login: {
      $extend: 'dropdown',
    },
    nudge: {
      $extend: 'dropdown',
    },
  },
})

registerPlugins(app)

app.config.errorHandler = (err) => {
  if (err instanceof Error) {
    logger.addErrorRecord(err)
  }

  if (import.meta.env.DEV) {
    throw err
  }
}

app.mount('#versuni-app')
