import { MutationCache, QueryCache, VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query'

import type { HttpError } from '/@front/utils/http/error/types/http'
import logger from '/@front/utils/logger'

const handleError = (error: HttpError) => {
  const status = error.status

  if ([401, 403].includes(status)) {
    const auth = queryCache.find({
      queryKey: ['auth/me'],
    })

    return auth?.fetch() // unfortunately invalidate() doesn't actually refetch the query and the query client is not available outside of vue setup context.
  }

  if (status !== 422) {
    logger.addErrorRecord(error)
  }
}

const queryCache = new QueryCache({
  onError: (error, query) => {
    if (query.queryKey[0] === 'auth/me') {
      return
    }

    handleError(error as HttpError)
  },
})

const mutationCache = new MutationCache({
  onError: (error) => {
    handleError(error as HttpError)
  },
})

export const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: { queryCache, mutationCache },
}

export default VueQueryPlugin
