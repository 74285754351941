export const infoRoutes = [
  {
    path: 'contracts',
    name: 'info-contracts',
    component: () => import('/@front/views/authenticated/state/shared/info/view/Contracts.vue'),
  },
  {
    path: 'portfolio',
    name: 'info-portfolio',
    component: () => import('/@front/views/authenticated/state/shared/info/view/Portfolio.vue'),
  },
  {
    path: 'priorities',
    name: 'info-priorities',
    component: () => import('/@front/views/authenticated/state/shared/info/view/Priorities.vue'),
  },
  {
    path: 'profit-loss',
    name: 'info-profit-loss',
    component: () => import('/@front/views/authenticated/state/shared/info/view/ProfitLoss.vue'),
  },
  {
    path: 'product-results',
    name: 'info-product-results',
    component: () => import('/@front/views/authenticated/state/shared/info/view/ProductResults.vue'),
  },
  {
    path: 'role',
    name: 'info-role',
    component: () => import('/@front/views/authenticated/state/shared/info/view/Role.vue'),
  },
  {
    path: 'sop',
    name: 'info-sop',
    component: () => import('/@front/views/authenticated/state/shared/info/view/Sop.vue'),
  },
  {
    path: 'ltjbp',
    name: 'info-ltjbp',
    component: () => import('/@front/views/authenticated/state/shared/info/view/Ltjbp.vue'),
  },
  {
    path: 'market-fit',
    name: 'info-market-fit',
    component: () => import('/@front/views/authenticated/state/shared/info/view/MarketFit.vue'),
  },
  {
    path: 'demand-delivery',
    name: 'info-demand-delivery',
    component: () => import('/@front/views/authenticated/state/shared/info/view/DemandDelivery.vue'),
  },
  {
    path: 'result-summary',
    name: 'info-result-summary',
    component: () => import('/@front/views/authenticated/state/shared/info/view/ResultSummary.vue'),
  },
]

export default [
  {
    path: '/',
    name: 'splash',
    component: () => import('/@front/views/Splash.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/@front/views/Login.vue'),
  },
  {
    path: '/authenticated',
    component: () => import('/@front/views/Authenticated.vue'),
    children: [
      {
        path: '/state',
        component: () => import('/@front/views/authenticated/State.vue'),
        children: [
          {
            path: '/participant',
            name: 'participant',
            component: () => import('/@front/views/authenticated/state/Participant.vue'),
            children: [
              {
                path: '/messages',
                name: 'messages',
                component: () => import('/@front/views/authenticated/state/participant/Messages.vue'),
              },
              {
                path: '/tasks',
                name: 'tasks',
                component: () => import('/@front/views/authenticated/state/participant/tasks/Index.vue'),
                children: [
                  {
                    path: ':task',
                    name: 'task',
                    component: () => import('/@front/views/authenticated/state/participant/tasks/View.vue'),
                  },
                ],
              },
              {
                path: '/info',
                name: 'participant-info',
                component: () => import('/@front/views/authenticated/state/shared/info/Index.vue'),
                children: infoRoutes.map((route) => {
                  return {
                    ...route,
                    name: `participant-${route.name}`,
                  }
                }),
              },
              {
                path: '/dashboard',
                name: 'participant-dashboard',
                component: () => import('/@front/views/authenticated/state/shared/dashboard/Index.vue'),
              },
              {
                path: '/pre-order',
                name: 'participant-pre-order',
                component: () => import('/@front/views/authenticated/state/shared/PreOrder.vue'),
              },
              {
                path: '/innovations',
                name: 'innovations',
                component: () => import('/@front/views/authenticated/state/participant/innovations/Innovations.vue'),
                redirect: { name: 'innovation-index' },
                children: [
                  {
                    path: '',
                    name: 'innovation-index',
                    component: () => import('/@front/views/authenticated/state/participant/innovations/Index.vue'),
                  },
                  {
                    path: ':innovation',
                    name: 'innovation-view',
                    component: () => import('/@front/views/authenticated/state/participant/innovations/View.vue'),
                    children: [
                      {
                        path: 'definitions',
                        name: 'innovation-definitions',
                        component: () =>
                          import('/@front/views/authenticated/state/participant/innovations/view/Definitions.vue'),
                      },
                      {
                        path: 'tasks',
                        name: 'innovation-tasks',
                        component: () =>
                          import('/@front/views/authenticated/state/participant/innovations/view/Tasks.vue'),
                      },
                      {
                        path: 'completed',
                        name: 'innovation-completed',
                        component: () =>
                          import('/@front/views/authenticated/state/participant/innovations/view/Completed.vue'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '/facilitator',
            name: 'facilitator',
            component: () => import('/@front/views/authenticated/state/Facilitator.vue'),
            redirect: { name: 'organization' },
            children: [
              {
                path: '/organization',
                name: 'organization',
                component: () => import('/@front/views/authenticated/state/facilitator/Organization.vue'),
              },
              {
                path: '/settings',
                name: 'settings',
                component: () => import('/@front/views/authenticated/state/facilitator/Settings.vue'),
              },
              {
                path: '/info',
                name: 'facilitator-info',
                component: () => import('/@front/views/authenticated/state/shared/info/Index.vue'),
                children: infoRoutes.map((route) => {
                  return {
                    ...route,
                    name: `facilitator-${route.name}`,
                  }
                }),
              },
              {
                path: '/pre-order',
                name: 'facilitator-pre-order',
                component: () => import('/@front/views/authenticated/state/shared/PreOrder.vue'),
              },
              {
                path: '/dashboard',
                name: 'facilitator-dashboard',
                component: () => import('/@front/views/authenticated/state/shared/dashboard/Index.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
]
