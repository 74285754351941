import ErrorStackParser from 'error-stack-parser'
import { debounce } from 'lodash-es'

import type { HttpError } from './http/error/types/http'

const url = `/errors/js`
const consoleMinLevel = import.meta.env.prod ? 300 : 0
const serverMinLevel = 300

declare let console: any

const logLevels: { [key: string]: number } = {
  DEBUG: 100,
  INFO: 200,
  NOTICE: 250,
  WARNING: 300,
  ERROR: 400,
  CRITICAL: 500,
  ALERT: 550,
  EMERGENCY: 600,
}

interface StackElement {
  column: number
  line: number
  file: string
}

interface Context {
  native?: boolean
  status?: number
  stack?: StackElement[]
  ua?: string
  payload?: any
  role?: string
  organization?: string
  url?: string
}

interface LogItem {
  level: string
  message: string
  context: Record<string, any>
}

export default new (class Logger {
  public logs: LogItem[] = []

  constructor() {
    this.attachEventListeners()
  }

  public addErrorRecord(error: Error) {
    let elements

    try {
      elements = ErrorStackParser.parse(error)
    } catch (e) {
      console.error('Could not parse', error)
      return
    }

    const stack: StackElement[] = elements.map((item) => {
      return {
        column: item.columnNumber,
        line: item.lineNumber,
        file: item.fileName,
      }
    }) as StackElement[]

    const context: Context = {
      native: true,
      url: location.href,
      stack,
    }

    const httpError = error as HttpError

    if (httpError.organization) {
      context.payload = httpError.payload
      context.role = httpError.role
      context.organization = httpError.organization
      context.status = httpError.status
      context.url = httpError.url
    }

    this.addLogRecord('ERROR', `${error.name} ${error.message}`, context)
  }

  private attachEventListeners() {
    window.addEventListener('beforeunload', () => {
      if (this.logs.length) {
        return this.sendBeacon()
      }
    })

    window.addEventListener('error', (event: ErrorEvent) => {
      this.addErrorRecord(event.error)
    })

    // 'capture' listener, listen to missing images etc
    window.addEventListener(
      'error',
      (event: ErrorEvent) => {
        if (event.target === window) {
          // these are handled by the previous listener
          return
        }

        const target: EventTarget = event.target as EventTarget

        if (target instanceof Element) {
          const tagName: string = target.tagName

          if (tagName === 'IMG') {
            // missing image
            return this.addLogRecord('WARNING', `Missing image: ${(target as HTMLImageElement).src}`)
          }

          if (tagName === 'image') {
            if (!target.getAttribute('xlink:href')?.length) {
              return
            }
          }

          return this.addLogRecord('WARNING', `Missing asset in ${tagName}`, {
            payload: {
              html: target.outerHTML,
            },
          })
        }
      },
      true,
    )

    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      if (event.reason instanceof Error) {
        this.addErrorRecord(event.reason)
      }
    })

    window.addEventListener('messageerror', () => {
      // failed to deserialize worker message
    })
  }

  private getLevelFromName(name: string) {
    return logLevels[name.toUpperCase()]
  }

  private addLogRecord(levelName: string, message: string, context: Context = {}) {
    if (typeof message === 'undefined' || !message.length) {
      return
    }

    // add UA to context
    context.ua = window.navigator.userAgent

    const log = {
      level: levelName,
      message,
      context,
    }

    this.logToConsole(log)
    if (this.getLevelFromName(log.level) < serverMinLevel) {
      return
    }

    this.logs.push(log)
    this.sendToServer()
  }

  private logToConsole(log: any) {
    if (this.getLevelFromName(log.level) < consoleMinLevel) {
      return
    }

    let methodName = log.level.toLowerCase()
    if (methodName === 'debug') {
      methodName = 'log'
    }

    if (typeof console === 'undefined') {
      return false
    } else if (console[methodName] !== undefined) {
      return console[methodName](log.message)
    } else if (console.log !== undefined) {
      return console.log(log.message)
    }
  }

  private collectData() {
    return { logs: this.logs }
  }

  private sendToServer = debounce(() => {
    this.sendBeacon()
  }, 5000)

  private sendBeacon() {
    const fd = new FormData()
    fd.append('logs', JSON.stringify(this.logs))
    fd.append('beacon', '1')

    navigator.sendBeacon(url, fd)

    this.logs = []
  }
})()
